<template>
  <div class="container py-5">
    <p class="fw-bold">第28屆中國美容博覽會</p>
    <p>時間：2024/05/22-24</p>
    <p>地點：上海新國際博覽中心‧浦東</p>
    <p>展位：E4C01</p>

    <p>
    CBE中國美容博覽會，迄今已連續成功舉辦27屆，是全球美妝產業鏈全局商貿服務平台，被譽為中國美妝市場的晴雨表和風向標。 第28屆CBE中國美容博覽會是亞洲區域內獨佔鰲頭的美容行業貿易盛會，也是眾多業界人士開拓我國市場乃至亞洲美容業的優選。
    <br> <br>
    第28 屆CBE 中國美容博覽會，將於2024年5 月22日-24 日，在上海新國際博覽中心（浦東）舉行，展覽面積可達220000平方米，覆蓋護膚、彩妝及美容工具、髮品 、護理用品、孕嬰童、香水香氛、口服保養品、家用美容儀、連鎖加盟及服務機構、專業美容產品及儀器、美甲美睫紋繡、OEM/ODM/OBM、包裝、機械設備、原料 /材質等類別，提供全球美妝產業全生態服務。
    <br> <br> 
    銘伊恆美集團一直致力於美容產品的研發與創新，並擁有許多備受市場歡迎的優質產品。 我們始終堅持以人為本，以客戶需求為導向，透過不斷的技術創新和產品升級，為客戶提供更安全、高效的美容解決方案。
    <br> <br>
    在本次博覽會上，我們將展示一系列最新研發的美容設備，我們相信，這些創新成果將為您帶來全新的體驗和感受，幫助您更能掌握市場趨勢，提升競爭力。
    <br>
    此外，我們有專業的技術團隊為您解答設備使用、技術等方面的問題。 我們期待與您一同感受美麗與科技的魅力，共同探討美容產業的未來發展。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_41.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
